<template>
    <div id="auth">
        <Header style="position: inherit;" :isAuthPage="true"/>
        <div class="logo">
            <div class="logo-cont">
                <span class="iconify" data-icon="emojione:framed-picture"></span>
            </div>
            <div class="logo-title">
                Logo
            </div>
        </div>
        <div class="auth-with" @click="googleSignIn">
            <div class="small-logo">
                <span class="iconify" data-icon="flat-color-icons:google" style="color: #135345;" data-width="19.2" data-height="19.2"></span>
            </div>
            <div class="auth-title">Continue with Google</div>
        </div>
        <AppleSignIn/>
        <div id="issue">
            <div class="issue-cont">
                <a href="">Issues with log in?</a>
            </div>
            
        </div>
    </div>
</template>

<script>
import Header from '../components/header/Header.vue'
import AppleSignIn from '../components/apple-signin/AppleSignIn.vue'
import CMS from '../service/cms/service'
import router from '../router'
import AuthService from '../service/auth-service.js'

export default {
    name: 'Auth',
    components: {
        Header,
        AppleSignIn
    },
    mounted(){
        let code = this.$route.query.code
        if (code){
            AuthService.signIn({code, authMethod: 1})
                .then(response => {
                    if (response.data.err)
                        throw response.data.err
                    this.setupUserInfo({
                        firstName : response.data.firstName,
                        lastName : response.data.lastName,
                        email : response.data.email,
                    })
                })
        }
    },
    methods: {
        googleSignIn(){
            AuthService.getOauthData(1)
                .then(data => {
                    if (data.err || !data.authUri)
                        return
                    window.open(data.authUri, "_self")
                })
        },

        setupUserInfo(userData){
            CMS.user.getInfo()
                .then(({data, status}) => {
                    if (data.err)
                        throw data.err
                    
                    this.$store.dispatch("setup-geo")

                    let isNewUser = status == 204
                    if (isNewUser) {
                        this.newUserHandler(userData)
                        return
                    }
                    
                    let profile = data
                    this.saveUserProfile(profile)

                    let lastCalendarInLocalStorage = Boolean(localStorage.getItem("last-calendar"))
                    if (lastCalendarInLocalStorage) {
                        router.replace(localStorage.getItem("last-calendar"))
                        localStorage.removeItem("last-calendar")
                        return
                    }

                    router.replace({name: 'calendar-list'}) // --> to calendar list
                })
        },

        newUserHandler(userData){
            localStorage.setItem('hora-user-first-name', userData.firstName)
            localStorage.setItem('hora-user-last-name', userData.lastName)
            localStorage.setItem('hora-user-email', userData.email)
            this.$store.dispatch('update-user-data', {
                isLogin: true,
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
            })
            router.push('welcome') // --> to welcome page
        },
        
        visitedCalendarHandler(){
            let calendarIsVisited = Boolean(this.$store.getters.calendarUid)
            if (calendarIsVisited){
                let lastCalendarVisibility = this.$store.getters.calendarVisibilityLevel
                let isPublicForUser = lastCalendarVisibility == 2 || lastCalendarVisibility == 3
                if (isPublicForUser){
                    let p = {calendarId: this.$store.getters.calendarUid}
                    let q = this.$store.getters.calendarProperties
                    router.replace({name:'calendar', params: p, query: q})
                    return
                }
            }
        },
        
        saveUserProfile(profile){
            let profileDate = {
                calendars: profile.calendars,
                firstName: profile.firstName,
                // no image url
                lastName: profile.lastName,
                shortName: profile.shortName,
                email: profile.email,
                
                countryId: profile.countryId,
                timezoneId: profile.timezoneId,
                workdayCalendarId: profile.workdayCalendarId,
            }

            this.$store.dispatch('update-user-data', {
                isLogin: true,
                ...profileDate
            })
        }
    },
}
</script>

<style scoped>
#auth{
    height: 92vh;
    position: relative;
}
.logo{
    height: 150px;
    width: 250px;
    margin: 60px 0 40px 0;
    margin-right: auto;
    margin-left:auto;
    background: #e6e6e6;
    border-radius: 10px;
}
.auth-with{
    padding: 10px 19px;
    border: 1px solid #e6e6e6;
    max-width: 270px;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.title{
    text-align: center;
}
.logo .iconify{
    height: 105px;
    width: 170px;
    color: grey;
}
.logo-cont{
    padding: 10px 40px 0 40px;
}
.logo-title{
    text-align: center;
    height: fit-content;
}
.small-logo{
    height: 19.2px;
}
#issue{
    font-size: 14px;
    position: absolute;
    bottom: 25px;
    left: 0;
}
.issue-cont{
    text-align: center;
    width: 100vw;
}
</style>