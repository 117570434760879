<template>
    <div class="auth-with" v-bind:class="{disabled: true}">
            <div class="small-logo">
                <span class="iconify" style="color: gray;" data-icon="logos:apple" data-width="19.2" data-height="19.2"></span>
            </div>
            <div class="auth-title">Continue with Apple</div>
        </div>
</template>

<script>
export default {
    name: "AppleSignIn",
    mounted(){
        // console.log('process.env.VUE_APP_REDIRECT_URI', process.env.VUE_APP_REDIRECT_URI)
        // let clientId = process.env.VUE_APP_APPLE_CLIENT_ID
        // let scope = 'name email'
        // let redirectURI = process.env.VUE_APP_REDIRECT_URI
        // let state = (new Date()).toString()
        // let usePopup = true

        // window.AppleID.auth.init({
        //   clientId,
        //   scope,
        //   redirectURI,
        //   state,
        //   usePopup
        // })
    }
}
</script>

<style scoped>
.auth-with{
    padding: 10px 19px;
    border: 1px solid #e6e6e6;
    max-width: 270px;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.small-logo{
    height: 19.2px;
}
.auth-title{
    text-align: center;
}
.logo .iconify{
    height: 105px;
    width: 170px;
    color: grey;
}
.disabled{
    color :grey;
    background: #e6e6e6;
}

.disabled .logo .iconify{
    color: grey;
}
</style>